






































































































































































import { Component, Vue } from 'vue-property-decorator'
import settingService from '@/services/settingService'
import { PriceMsgContent, Price } from '@/services/data'
import utility from '@/common/utility'
import ws, { SocketMsg } from '@/services/socket'
import symbolService, { OptionScanRes } from '@/services/symbolService'
import SymbolName from '@/components/SymbolName.vue'
import NumberRowEditor from '@/components/NumberRowEditor.vue'
import SetAlertPrice from '@/components/SetAlertPrice.vue'

interface ExpDateGroup {
  Expiration: string;
  Options: OptRow[];
  stkStartIndex?: number;
  stkEndIndex?: number;
}

interface OptItemRow {
  OptionUId: number;
  OptionId: number;
  Bid: number;
  Ask: number;
  Last: number;
  Otm: number;
  Alert: boolean;
  IsValid: boolean;
  Capture: { times: number; state: number };
}

interface OptRow {
  Call: OptItemRow;
  Put: OptItemRow;
  Strike: number;
  IsActive: boolean;
}

@Component({
  components: {
    SymbolName,
    NumberRowEditor,
    SetAlertPrice
  }
})
export default class Watchlist extends Vue {
  optionDef: OptionScanRes = null
  dateGroups: ExpDateGroup[] = []
  currentGroup: ExpDateGroup = { stkEndIndex: null, stkStartIndex: null, Options: [], Expiration: null }
  loading = false
  symbolId: number = null
  expiration: string = null
  selectedExp: string = null
  stkPrice: Price = null
  range_low = 0
  range_high = 5
  minStartCount = -15
  maxEndCount = 15
  countRange: number[] = [-10, 10]
  isBuildingRows = false
  lastUpdated = ''
  dataSource = ''
  alertPriceSettingDialog = false
  isAuto = false
  runningInterval: number
  currentRow: { row: OptItemRow; strike: number; right: string } = null
  pageContext: { symbols: number[]; options: number[] } = {
    symbols: [],
    options: []
  }

  async mounted () {
    ws.InitAll(this.msgCallBack)
    this.symbolId = parseInt(this.$route.query.symbolId as string)
    if (isNaN(this.symbolId)) return
    this.loading = true
    const spRes = await symbolService.getSymbolPriceAsync(this.symbolId)
    if (spRes.Result && spRes.Result.Price) {
      this.stkPrice = spRes.Result.Price
    }
    const dsRes = (await settingService.readUserSettingsByNamesAsync(['StockDataSource'])).Result
    if (dsRes.length > 0) this.dataSource = dsRes[0].Value
    const defRes = await symbolService.getOptionDefInfo(this.symbolId)
    if (defRes.Result != null) {
      utility.freezeArrayAndItems(defRes.Result.Strikes)
      utility.freezeArrayAndItems(defRes.Result.Expirations)
      this.optionDef = defRes.Result
      if (defRes.Result.Expirations.length > 0) {
        let count = 0
        for (const exp of defRes.Result.Expirations) {
          this.dateGroups.push({ Expiration: exp, Options: [] })
          count++
          if (count >= 4) break
        }
        await this.setActiveTab(this.dateGroups[0])
      }
    } else {
      await this.$alert(defRes.Error)
    }
    this.loading = false
    if (this.lastUpdated === '') {
      this.lastUpdated = sessionStorage.getItem(this.symbolId + 'LASTUPDATED')
    }
    this.runningInterval = setInterval(async () => {
      await this.getSubStatus()
    }, 5000)
  }

  $num (num: number): string {
    if (num != null && num !== undefined) {
      return num.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })
    }
    return null
  }

  async getSubStatus () {
    const ws = await symbolService.getWatchedOptions(this.symbolId)
    ws.Result.forEach(p => {
      this.currentGroup.Options.forEach(v => {
        if (v.Call.OptionId === p.OptionId) {
          v.Call.Capture.state = 1
        }
        if (v.Put.OptionId === p.OptionId) {
          v.Put.Capture.state = 1
        }
      })
    })
  }

  getOtm (right: string, strike: number) {
    return right === 'C'
      ? (((strike / this.stkPrice.Last) - 1) * 100)
      : ((1 - (strike / this.stkPrice.Last)) * 100)
  }

  destroyed (): void {
    ws.RemoveAll(this.msgCallBack)
    if (this.runningInterval) clearInterval(this.runningInterval)
  }

  async msgCallBack (msg: SocketMsg) {
    if (this.optionDef != null) {
      if (msg.Action === 'resStk' && this.optionDef.Symbol != null) {
        if (this.optionDef.Symbol.SymbolUId === msg.MsgId) {
          const content = msg.Content as PriceMsgContent
          if (content.Name === 'Last') {
            this.stkPrice.Last = content.Value
            this.calculateRowValues()
          }
        }
      } else if (msg.Action === 'resOpt') {
        const content = msg.Content as PriceMsgContent
        this.currentGroup.Options.forEach(v => {
          if (v.Call.OptionUId === msg.MsgId) {
            this.lastUpdated = msg.Content.LastTime
            sessionStorage.setItem(this.symbolId + 'LASTUPDATED', this.lastUpdated)
            if (content.Name === 'OptPrice') {
              v.Call.Last = content.Value
            } else if (content.Name === 'Bid') {
              v.Call.Bid = content.Value
            } else if (content.Name === 'Ask') {
              v.Call.Ask = content.Value
            }
            v.Call.Capture.state = 1
            return
          }
          if (v.Put.OptionUId === msg.MsgId) {
            this.lastUpdated = msg.Content.LastTime
            sessionStorage.setItem(this.symbolId + 'LASTUPDATED', this.lastUpdated)
            if (content.Name === 'OptPrice') {
              v.Put.Last = content.Value
            } else if (content.Name === 'Bid') {
              v.Put.Bid = content.Value
            } else if (content.Name === 'Ask') {
              v.Put.Ask = content.Value
            }
            v.Put.Capture.state = 1
          }
        })
      } else if (msg.Action === 'resTimeout') {
        const timeoutIdArr = msg.Content as number[]
        timeoutIdArr.forEach(p => {
          this.currentGroup.Options.forEach(v => {
            if (v.Call.OptionId === p) {
              v.Call.Capture.times++
              v.Call.Capture.state = 0
            }
            if (v.Put.OptionId === p) {
              v.Put.Capture.times++
              v.Put.Capture.state = 0
            }
          })
        })
      }
    }
  }

  get currentOptName () {
    return `${this.optionDef.Symbol.Symbol} ${this.currentRow.right} @ ${this.currentRow.strike} Expire ${this.currentGroup.Expiration}`
  }

  setAlertPrice (row: OptItemRow, strike: number, right: string) {
    this.currentRow = { row: row, strike: strike, right: right }
    this.alertPriceSettingDialog = true
  }

  alertAdded (res: { symbolId: number; count: number }) {
    this.currentRow.row.Alert = res.count > 0
  }

  async setActiveTab (group: ExpDateGroup) {
    this.currentGroup = group
    this.loading = true
    const options = await symbolService.generateOptions(this.symbolId, group.Expiration)
    const optAlerts = await symbolService.getAlertPricesAsync({ symbolId: this.symbolId, type: 'Option' })
    this.loading = false
    if (options.Result) {
      const rows: OptRow[] = []
      for (const s of options.Result.Data) {
        const state = (options.Result.MarketState !== 8 ? 1 : 0) // check market state
        let find = rows.find(p => p.Strike === s.Option.Strike)
        if (find == null) {
          find = {
            Call: {
              OptionId: null,
              OptionUId: null,
              Otm: null,
              Last: null,
              Ask: null,
              Bid: null,
              Alert: false,
              IsValid: false,
              Capture: { times: 1, state: state }
            },
            Put: {
              OptionId: null,
              OptionUId: null,
              Otm: null,
              Last: null,
              Ask: null,
              Bid: null,
              Alert: false,
              IsValid: false,
              Capture: { times: 1, state: state }
            },
            Strike: s.Option.Strike,
            IsActive: false
          }
          rows.push(find)
        }
        if (s.Option.Right === 'C') {
          if (s.IsWatched) find.Call.Capture.state = 1
          find.Call.OptionId = s.Option.OptionId
          find.Call.OptionUId = s.Option.OptionUId
          find.Call.Last = s.Price.Last
          find.Call.Bid = s.Price.Bid
          find.Call.Ask = s.Price.Ask
          find.Call.Alert = (optAlerts.Result.findIndex(p => p.OptionId === s.Option.OptionId) >= 0)
        } else {
          if (s.IsWatched) find.Put.Capture.state = 1
          find.Put.OptionId = s.Option.OptionId
          find.Put.OptionUId = s.Option.OptionUId
          find.Put.Last = s.Price.Last
          find.Put.Bid = s.Price.Bid
          find.Put.Ask = s.Price.Ask
          find.Put.Alert = (optAlerts.Result.findIndex(p => p.OptionId === s.Option.OptionId) >= 0)
        }
      }
      rows.sort((a, b) => {
        return a.Strike - b.Strike
      })
      this.currentGroup.Options = rows
      this.calculateRowValues()
    } else {
      await this.$alert(options.Error)
    }
  }

  private setAutoDisplay () {
    this.isAuto = true
    this.calculateRowValues()
  }

  private strikeSliderChanged () {
    this.isAuto = false
    this.calculateRowValues()
  }

  private calculateRowValues () {
    if (this.isBuildingRows) return
    this.isBuildingRows = true
    for (const s of this.currentGroup.Options) {
      s.Call.Otm = this.getOtm('C', s.Strike)
      s.Put.Otm = this.getOtm('P', s.Strike)
    }
    const end = this.currentGroup.Options.findIndex(p => p.Strike >= this.stkPrice.Last)
    this.currentGroup.stkEndIndex = end
    if (end > 0 && this.currentGroup.Options[end].Strike !== this.stkPrice.Last) {
      this.currentGroup.stkStartIndex = end - 1
    } else {
      this.currentGroup.stkStartIndex = end
    }

    if (this.isAuto) {
      let callCount = 0
      let putCount = 0
      this.currentGroup.Options.forEach((v) => {
        v.Call.IsValid = (v.Call.Otm >= 0 && v.Call.Otm <= this.range_high)
        if (v.Call.IsValid) callCount++
        v.Put.IsValid = (v.Put.Otm >= 0 && v.Put.Otm <= this.range_high)
        if (v.Put.IsValid) putCount++
        v.IsActive = v.Call.IsValid || v.Put.IsValid
      })
      this.countRange[0] = -putCount
      this.countRange[1] = callCount
    } else {
      const callValidIndex = Math.max(0, this.currentGroup.stkStartIndex - 4)
      const putValidIndex = Math.min(this.currentGroup.Options.length - 1, this.currentGroup.stkEndIndex + 4)
      this.currentGroup.Options.forEach((v, i) => {
        v.Call.IsValid = (i >= callValidIndex)
        v.Put.IsValid = (i <= putValidIndex)
      })

      const minVisibleIndex = Math.max(0, this.currentGroup.stkStartIndex + this.countRange[0])
      const maxVisibleIndex = Math.min(this.currentGroup.Options.length, this.currentGroup.stkEndIndex + this.countRange[1])
      this.minStartCount = (this.currentGroup.stkStartIndex) * -1
      this.maxEndCount = (this.currentGroup.Options.length - 1 - this.currentGroup.stkEndIndex)
      this.currentGroup.Options.forEach((v, index) => {
        v.IsActive = index >= minVisibleIndex && index <= maxVisibleIndex
      })
    }

    this.sendContext()
    this.isBuildingRows = false
  }

  sendContext () {
    const optIdArr: number[] = []
    this.currentGroup.Options.forEach(v => {
      if (v.Call.IsValid && v.IsActive) {
        optIdArr.push(v.Call.OptionId)
      }
      if (v.Put.IsValid && v.IsActive) {
        optIdArr.push(v.Put.OptionId)
      }
    })
    if (this.pageContext.symbols.length === 1 &&
      this.pageContext.symbols[0] === this.symbolId) {
      if (this.pageContext.options.length === optIdArr.length &&
        this.pageContext.options.every(p => optIdArr.indexOf(p) >= 0)) {
        return
      }
    }
    this.pageContext.symbols = [this.symbolId]
    this.pageContext.options = optIdArr
    ws.SendAll({ page: 'symbolOptList', symbols: [this.symbolId], options: optIdArr })
  }

  async addTab () {
    if (this.dateGroups.findIndex(p => p.Expiration === this.selectedExp) < 0) {
      this.dateGroups.push({ Expiration: this.selectedExp, Options: [] })
    }
  }
}
